<template>
  <div class="container" ref="container">
    <form @submit.prevent="submit()">
      <page-header
        :title="'상세한 상담이나\n전문가 추천이 필요하다면?'"
        titleSize="3.6rem"
        :subtitle="'헤이비글의 전문 상담팀에 문의하세요!'"
        />

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-2"></div>
        <h4>행사명 또는 필요한 분야</h4>
        <div class="ui-border-line ui-h-0 ui-mb-0"></div>

        <div class="ui-border-line"></div>
        <input
          type="text"
          v-model="detailData.genre"
          required
          placeholder="EX : 기업행사 사회자, 축제 공연팀, 행사기획 등"
          />
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-2"></div>
        <h4>필요한 날짜와 시간</h4>
        <div class="ui-border-line ui-h-0 ui-mb-0"></div>

        <div class="ui-border-line"></div>
        <input
          type="text"
          v-model="detailData.date"
          placeholder="EX : 2018.12.24 / 13시 30분"
          required
          />
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-2"></div>
        <h4>진행 지역 또는 위치</h4>
        <div class="ui-border-line ui-h-0 ui-mb-0"></div>

        <div class="ui-border-line"></div>
        <input
          type="text"
          v-model="detailData.location"
          placeholder="EX : 서울시 강남구 00빌딩 등"
          required
          />
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-2"></div>
        <h4>예산 (비용)</h4>
        <div class="ui-border-line ui-h-0 ui-mb-0"></div>

        <div class="ui-border-line"></div>
        <number-input
          :value="detailData.price"
          @input="val => detailData.price = val"
          required
          />
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-2"></div>
        <h4>필요한 내용을 상세히 알려주세요.</h4>
        <p :style="{fontSize: '18px'}"
          >원하는 조건, 진행 컨셉 등</p>
        <div class="ui-border-line ui-h-0 ui-mb-0"></div>

        <div class="ui-border-line"></div>
        <textarea
          type="text"
          v-model="detailData.etc"
          />
      </div>

      <div class="ui-border-0 ui-mb-6"></div>

      <footer-box
        submitText="견적 문의하기"
        :submitCb="() => {}"
        :submitDisabled="!validate"
      >
      </footer-box>
    </form>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import device from 'current-device'
import NumberInput from '@/components/common/NumberInput'

export default {
  name: 'Consulting',
  components: {
    PageHeader,
    FooterBox,
    NumberInput,
  },
  data() {
    const userData = this.$store.state.user.userData
    const platform = sessionStorage.getItem('platform')
    return {
      formData: {
        name: userData.userName, // 이름
        email: userData.phone, // 전화번호
        detail: '', // 내용
        device: platform, // 디바이스종류
        os: device.os, // OS정보
      },
      detailData: {
        genre: '',
        date: '',
        location: '',
        price: '',
        etc: '',
      },
    }
  },
  computed: {
    validate() {
      let validation = true
      for (let key in this.detailData) {
        if (['genre', 'date', 'location', 'price', 'etc'].indexOf(key) !== -1 && !this.detailData[key]) {
          validation = false
          break
        }
      }
      return validation
    },
  },
  methods: {
    submit() {
      if (!this.validate) return

      this.formData.detail = '상담 요청하기\n'
      + `필요 내용 (행사명+분야) : ${this.detailData.genre}\n`
      + `날짜 / 시간 : ${this.detailData.date}\n`
      + `지역 및 위치 : ${this.detailData.location}\n`
      + `섭외 예산 (비용) : ${this.detailData.price}\n`
      if (this.detailData.etc) {
        this.formData.detail += `\n기타 문의 내용 : ${this.detailData.etc}`
      }

      const req = {
        method: 'post',
        url: `/user/contact`,
        data: this.formData,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast('문의가 전달되었습니다').back()
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  }
}
</script>

<style lang="scss" scoped>
h4 {
  margin: 0 !important;
  font-weight: 500 !important;
  font-size: 2.6rem !important;
  line-height: 6.6rem !important;
}

input, textarea {
  padding: 0;
  width: 100%;
  border: 0;
  font-size: 2rem;
  line-height: 3rem;
}

input {
  height: 10rem;
}

textarea {
  min-height: 14rem;
  padding: 3rem 0;
}

p {
  color: #979797;
  font-size: 2rem;
  a {
    color: #000;
    text-decoration: underline;
    text-underline-position: under;
  }
}
</style>
